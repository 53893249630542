import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import GenerateSlug from 'src/helper/GenerateSlug';
import { ConfirmAlert, useAlert } from 'src/hooks/alerts';
import useForm from 'src/hooks/useForm';
import useTitle from 'src/hooks/useTitle';
import { RootState } from 'src/store';
import { invoiceStatus } from '.';

export default function id() {
  let rerender: boolean = true;
  const { id }: any = useParams();
  const { updateTitle } = useTitle();
  const navigate = useNavigate();
  const [response, setResponse] = useState<any | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [receiptVisible, setReceiptVisible] = useState<boolean>(false);
  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  useEffect(() => {
    if (rerender) {
      Promise.all([GetInvoice()]).finally(() => setIsLoading(false));
      rerender = false;
    }
  }, []);
  async function GetInvoice() {
    try {
      const { data } = await axiosInstance.get('/invoices/' + id);
      const invoice = data.result?.invoice;
      setResponse(invoice);
      updateTitle({
        title:
          invoice?.invoice_no ||
          invoice?.client_name ||
          invoice?.branch?.name ||
          invoice?.store?.name
      });
    } catch (error) {
      console.log('error while sending request..');
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  }

  const UpdateStatus = React.useCallback(async function ({
    target
  }: {
    target: HTMLSelectElement;
  }) {
    try {
      const value = target.value;
      const { data } = await axiosInstance.post('invoices/update_status', {
        id,
        status: value
      });
      alert.show({
        text: data?.message,
        visible: true
      });
      await GetInvoice();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        alert.show({
          text: err?.message,
          visible: true
        });
        return;
      }
    }
  },
  []);

  const DeleteItem = useCallback(async function () {
    try {
      const { data } = await axiosInstance.post('invoices/delete/' + id);
      navigate('/', { replace: true });
    } catch (error: AxiosError | any) {
      alert.show({
        text: error.response?.data?.message,
        visible: true
      });
    }
  }, []);

  const CloseItem = useCallback(async function () {
    try {
      const { data } = await axiosInstance.post('invoices/close/' + id);
      await GetInvoice();
      alert.show({
        text: data?.message,
        visible: true
      });
    } catch (error: AxiosError | any) {
      alert.show({
        text: error.response?.data?.message,
        visible: true
      });
    }
  }, []);

  const DownloadInvoice = useCallback(async function (status: string) {
    try {
      let link = document.createElement('a'),
        url: string;

      const { data } = await axiosInstance.post(
        'invoices/download',
        { id, status },
        {
          responseType: 'blob'
        }
      );

      url = URL.createObjectURL(new Blob([data]));

      link.href = url;
      link.download = GenerateSlug(id + ' ' + status + ' ' + new Date().getTime() + '.pdf');

      link.click();
      URL.revokeObjectURL(url);
      link.remove();
      // await queryRef.current?.Fetch();
    } catch (error: any) {}
  }, []);

  const DownloadVoucher = useCallback(async function (voucher_id: string) {
    try {
      let link = document.createElement('a'),
        url: string;

      const { data } = await axiosInstance.post(
        'invoices/download_receipt_voucher/' + voucher_id,
        {},
        {
          responseType: 'blob'
        }
      );

      url = URL.createObjectURL(new Blob([data]));

      link.href = url;
      link.download = GenerateSlug(
        'receipt-voucher-' + voucher_id + '-' + new Date().getTime() + '.pdf'
      );

      link.click();
      URL.revokeObjectURL(url);
      link.remove();
      // await queryRef.current?.Fetch();
    } catch (error: any) {}
  }, []);
  15;
  const VATPercentage = useMemo(() => {
    const pricePercentage = (response?.vat / response?.sub_total) * 100;
    console.log(pricePercentage);
    return pricePercentage.toFixed(2);
  }, [response]);

  return (
    <div className="p-8 space-y-8 relative h-full  overflow-x-auto grid">
      {isLoading ? (
        <LoadingComponent position="absolute" />
      ) : (
        <Fragment>
          <div className="grid overflow-x-auto">
            <div className="lg:container mx-auto space-y-4">
              <div>
                <div className="flex gap-2 flex-wrap justify-end">
                  {['closed', 'cancelled', 'vat_invoice'].includes(response?.status) ? null : (
                    <>
                      <Link
                        to={'/invoices/' + id + '/update'}
                        className="btn-with-icon bg-neutral-200 !text-neutral-600 shrink-0"
                      >
                        <Icon
                          icon="mdi:receipt-text-edit-outline"
                          width={18}
                          height={18}
                        />
                        <span>Update</span>
                      </Link>
                      {/* <button
                        className="btn-with-icon bg-neutral-200 !text-neutral-600 shrink-0"
                        onClick={() => setCloseConfirm(true)}
                      >
                        <Icon
                          icon="ic:baseline-close"
                          width={18}
                          height={18}
                        />
                        <span>Close</span>
                      </button> */}
                      {user?.user_type === 'super_admin' && (
                        <button
                          className="btn-with-icon bg-red-500/10 !text-red-500 shrink-0"
                          onClick={() => setDeleteConfirm(true)}
                        >
                          <Icon
                            icon="fluent:delete-32-regular"
                            width={18}
                            height={18}
                          />
                          <span>Delete</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex gap-4 flex-col-reverse lg:flex-row items-start">
                <div className="flex-1 w-full lg:max-w-sm border border-neutral-200 rounded-xl divide-y divide-neutral-200">
                  <header className="p-4 flex justify-between">
                    <p className="text-base text-neutral-800 font-semibold">Invoice tracking</p>
                  </header>

                  {!response?.statuses?.length ? (
                    <p className="text-sm text-neutral-500 p-4">Invoice just created</p>
                  ) : (
                    <ul className="py-4 px-6 space-y-2">
                      {response?.statuses?.map((status: any, index: string | number) => (
                        <li key={index}>
                          <div className="flex gap-4 items-stretch">
                            <div className="relative flex flex-col justify-center items-center">
                              <span className="w-3 h-3 rounded-full bg-primary block shrink-0 relative mt-2">
                                <span className="absolute w-full h-full animate-ping bg-primary/50 z-[-1] rounded-full"></span>
                              </span>
                              <span className="flex-1 border border-dashed border-neutral-300"></span>
                            </div>
                            <div className="space-y-1 flex-1">
                              <p className="text-base text-neutral-500 font-medium">
                                {status.status}
                              </p>
                              <p className="text-sm text-neutral-800">
                                Status changed by:{' '}
                                <span className="text-neutral-800 font-semibold">
                                  {status?.admin?.name}
                                </span>
                              </p>
                              <SharedTime date={status?.created_at} />
                            </div>
                            {['quotation', 'delivery_receipt', 'vat_invoice'].includes(
                              status.status
                            ) && (
                              <button
                                className="btn-with-icon bg-neutral-200 !text-neutral-600 shrink-0 self-start"
                                onClick={() => DownloadInvoice(status.status)}
                              >
                                <Icon
                                  icon="ri:download-cloud-line"
                                  width={18}
                                  height={18}
                                />
                              </button>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="flex-1 w-full border border-neutral-200 rounded-xl divide-y divide-neutral-200">
                  {/* <div className="p-6">
                    <Image
                      src="/images/logo.png"
                      className="w-full max-w-[8rem] mx-auto block"
                    />
                  </div> */}
                  <header className="p-4 flex justify-between items-center">
                    <p className="text-base text-neutral-800 font-semibold">Invoice details</p>
                    <div>
                      <select
                        name="invoice-status"
                        id="invoice-status"
                        className="form-select"
                        onChange={UpdateStatus}
                        value={response?.status}
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          Status
                        </option>

                        {invoiceStatus?.map((ev: string, index: string | number) => {
                          const statusIndex = invoiceStatus?.findIndex(
                            (e: string) => e == response?.status
                          );
                          return (
                            <option
                              value={ev}
                              key={index}
                              disabled={index < statusIndex}
                            >
                              {ev}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </header>
                  {response?.paid_amount && +(response?.total - response?.paid_amount) ? (
                    <p className="text-sm p-4 font-medium text-neutral-500 bg-neutral-50 rounded">
                      <span className="text-teal-600 font-semibold">
                        {CurrencyFormatter(response?.paid_amount)}
                      </span>{' '}
                      of the total{' '}
                      <span className="text-neutral-800 font-semibold">
                        {CurrencyFormatter(response?.total)}
                      </span>{' '}
                      has been paid and{' '}
                      <span className="text-red-500 font-semibold">
                        {CurrencyFormatter(response?.total - response?.paid_amount)}
                      </span>{' '}
                      is left
                    </p>
                  ) : null}
                  <div>
                    <ul className="grid grid-cols-1 sm:grid-cols-2 gap-px bg-neutral-200">
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Payment</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.payment_method || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Paid amount</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {CurrencyFormatter(response?.paid_amount) || 'N/A'}{' '}
                          {/* {response?.paid_amount && response?.total - response?.paid_amount && (
                            <span className="text-red-500">
                              {CurrencyFormatter(response?.total - response?.paid_amount)}
                            </span>
                          )} */}
                        </p>
                      </li>
                      {response?.payment_deadline && (
                        <li className="bg-white p-4 space-y-1 sm:col-span-2">
                          <p className="text-sm text-neutral-500 font-medium">Payment deadline</p>
                          <p className="text-base text-neutral-800 font-semibold">
                            {response?.payment_deadline}
                          </p>
                        </li>
                      )}
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Vat</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {CurrencyFormatter(response?.vat)}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Discount</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {CurrencyFormatter(response?.discount)}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Sub total</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {CurrencyFormatter(response?.sub_total)}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Total</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {CurrencyFormatter(response?.total)}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1 sm:col-span-2">
                        <p className="text-sm text-neutral-500 font-medium">Closed</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.is_close ? (
                            <div className="inline-flex gap-2 items-center">
                              Closed at: <SharedTime date={response?.closed_at} />
                            </div>
                          ) : (
                            'No'
                          )}
                        </p>
                      </li>{' '}
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Invoice no.</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.invoice_no || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Invoice Date</p>
                        <p>
                          <SharedTime date={response?.invoice_date} />
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Salesman</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.admin?.name || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Client</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.client_name || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Store</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.store?.name || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Branch</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.branch?.name || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">Region</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.branch?.region?.name || 'N/A'}
                        </p>
                      </li>
                      <li className="bg-white p-4 space-y-1">
                        <p className="text-sm text-neutral-500 font-medium">City</p>
                        <p className="text-base text-neutral-800 font-semibold">
                          {response?.branch?.city?.name || 'N/A'}
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="flex items-center justify-between gap-4  p-4">
                      <p className="text-sm text-neutral-500">Receipt vouchers</p>
                      {!response?.is_close && (
                        <button
                          className="btn-with-icon outline-btn !text-neutral-600 shrink-0"
                          onClick={() => setReceiptVisible(true)}
                        >
                          <span>Receipt voucher</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <ul className="divide-y divide-neutral-100 px-6">
                    {response?.receipt_vouchers?.map((item: any, index: number) => {
                      return (
                        <li
                          className="py-4"
                          key={index}
                        >
                          <div className="flex items-center gap-4">
                            <div className="flex-1">
                              <p className="text-sm font-medium text-neutral-800">
                                {item.payment_method}
                              </p>
                              {item.payment_method === 'cheque' ? (
                                <>
                                  <p className="text-sm font-medium text-neutral-500">
                                    Cheque number: {item.cheque_num}
                                  </p>
                                  <p className="text-sm font-medium text-neutral-500">
                                    Cheque bank: {item.cheque_bank}
                                  </p>
                                </>
                              ) : null}
                              <button
                                className="btn-with-icon bg-neutral-200 !text-neutral-600 shrink-0 !mt-2"
                                onClick={() => DownloadVoucher(item.id)}
                              >
                                <Icon
                                  icon="ph:file-pdf-duotone"
                                  width={18}
                                  height={18}
                                />
                                <span>Download receipt voucher</span>
                              </button>
                            </div>
                            <div>
                              <p className="shrink-0 text-lg text-primary font-bold">
                                {CurrencyFormatter(item.amount)}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    {!response?.receipt_vouchers?.length && (
                      <p className="text-sm py-4 font-medium text-neutral-600">
                        There are no receipts added yet
                      </p>
                    )}
                  </ul>
                  <p className="text-sm text-neutral-500  p-4">Other attachments</p>
                  <div>
                    <ul className="inline-flex gap-2 flex-wrap px-6">
                      {response?.attachments?.map((attachment: any, index: number) => {
                        return (
                          <li
                            className="py-4"
                            key={index}
                          >
                            <Link
                              className="btn-with-icon bg-neutral-200 !text-neutral-600 shrink-0"
                              to={attachment.file}
                            >
                              <span>{attachment.file}</span>
                            </Link>
                          </li>
                        );
                      })}
                      {!response?.attachments?.length && (
                        <p className="text-sm  py-4 font-medium text-neutral-600">
                          There are no attachments added yet
                        </p>
                      )}
                    </ul>
                  </div>
                  <p className="text-sm text-neutral-500  p-4">
                    Items ({response?.products?.length || 0})
                  </p>
                  <ul className="divide-y divide-neutral-100 px-6">
                    {response?.products?.map((product: any, index: number) => {
                      return (
                        <li
                          className="py-4"
                          key={index}
                        >
                          <div className="flex items-center gap-4">
                            {/* <Image
                              src={product.image}
                              className="w-12 h-12 rounded-full object-cover shrink-0"
                            /> */}
                            <div className="flex-1 space-y-1">
                              <p className="text-sm font-medium text-neutral-800">{product.name}</p>

                              <p className="text-sm font-medium text-neutral-500 ">
                                The total quantity for that product is{' '}
                                <span className="text-neutral-800 font-semibold">
                                  ( {product.qty || 1} )
                                </span>{' '}
                                , including{' '}
                                <span className="text-neutral-800 font-semibold">
                                  ({product.weight} KG)
                                </span>
                                , and the price per kilo is{' '}
                                <span className="text-neutral-800 font-semibold">
                                  {CurrencyFormatter(product.price)}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="shrink-0 text-lg text-primary font-bold">
                                {CurrencyFormatter((product.weight || 1) * product.price)}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <li className="py-4">
                      <div className="flex items-center gap-4">
                        {/* <Image
                              src={product.image}
                              className="w-12 h-12 rounded-full object-cover shrink-0"
                            /> */}
                        <div className="flex-1 space-y-1">
                          <p className="text-base text-neutral-800 font-semibold">Total:</p>
                          <p className="text-sm font-medium text-neutral-500 ">
                            Total products include{' '}
                            <span className="text-neutral-800 font-semibold">{VATPercentage}%</span>{' '}
                            of the VAT, at a value of{' '}
                            <span className="text-neutral-800 font-semibold">
                              {CurrencyFormatter(response?.vat)}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="shrink-0 text-lg text-primary font-bold">
                            {CurrencyFormatter(response?.total)}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal
            visible={receiptVisible}
            handleClose={() => setReceiptVisible(false)}
          >
            <AddReceiptVoucher
              closeModal={setReceiptVisible}
              reFetching={GetInvoice}
              id={id}
            />
          </Modal>
          <ConfirmAlert
            visible={closeConfirm}
            requestClose={function (): void {
              setCloseConfirm(false);
            }}
            handleConfirm={function (): void {
              CloseItem();
              setCloseConfirm(false);
            }}
          />
          <ConfirmAlert
            visible={deleteConfirm}
            requestClose={function (): void {
              setDeleteConfirm(false);
            }}
            handleConfirm={function (): void {
              DeleteItem();
              setDeleteConfirm(false);
            }}
          />
        </Fragment>
      )}
    </div>
  );
}

interface ReceiptFormInterface {
  amount: string | undefined;
  payment_method: string | undefined;
  cheque_num: string | undefined;
  cheque_bank: string | undefined;
  invoice_id: string | undefined;
}

const AddReceiptVoucher = ({ id, closeModal, reFetching }: any): JSX.Element => {
  const globalValues = {
    amount: undefined,
    payment_method: undefined,
    cheque_num: undefined,
    cheque_bank: undefined,
    invoice_id: id
  } satisfies ReceiptFormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<ReceiptFormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('invoices/add_receipt_voucher', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { handleSubmit, values, setFieldValue, handleChange }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">Amount</label>
        <input
          type="number"
          className="form-input"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="true"
          value={values.amount}
          name="amount"
          step="any"
          onChange={handleChange}
          placeholder="0.00 SAR"
        />
        {errors?.amount ? <span className="form-error">{errors?.amount}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Payment Type</label>
        <div>
          <ul className="space-y-2 !mt-4">
            <li>
              <div className="inline-flex gap-3 items-center">
                <input
                  type="radio"
                  required
                  autoComplete="off"
                  name="product-type"
                  className="form-radio"
                  onChange={() => setFieldValue('payment_method', 'cheque')}
                  value="cheque"
                  checked={values.payment_method === 'cheque'}
                  id="cheque"
                />
                <label
                  className="form-label"
                  htmlFor="cheque"
                >
                  Cheque
                </label>
              </div>
            </li>

            <li>
              <div className="inline-flex gap-3 items-center">
                <input
                  type="radio"
                  required
                  autoComplete="off"
                  name="product-type"
                  className="form-radio"
                  onChange={() => setFieldValue('payment_method', 'cash')}
                  value="cash"
                  checked={values.payment_method === 'cash'}
                  id="cash"
                />
                <label
                  className="form-label"
                  htmlFor="cash"
                >
                  Cash
                </label>
              </div>
            </li>
          </ul>
        </div>
        {errors?.payment_method ? (
          <span className="form-error">{errors?.payment_method}</span>
        ) : null}
      </div>

      {values.payment_method === 'cheque' ? (
        <>
          <div className="form-group">
            <label className="form-label">Cheque number</label>
            <input
              type="text"
              className="form-input"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="true"
              value={values.cheque_num}
              name="cheque_num"
              onChange={handleChange}
              placeholder="..."
            />
            {errors?.cheque_num ? <span className="form-error">{errors?.cheque_num}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">Bank</label>
            <input
              type="text"
              className="form-input"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="true"
              value={values.cheque_bank}
              name="cheque_bank"
              onChange={handleChange}
              placeholder="..."
            />
            {errors?.cheque_bank ? <span className="form-error">{errors?.cheque_bank}</span> : null}
          </div>
        </>
      ) : null}
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};
