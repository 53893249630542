import { Icon } from '@iconify/react';
import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import Image from 'src/components/shared/Image';
import Table from 'src/components/shared/tables';
import { Link } from 'react-router-dom';
import DotStatus from 'src/components/shared/DotStatus';
import SharedTime from 'src/components/shared/SharedTime';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { pick } from 'lodash';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import GenerateSlug from 'src/helper/GenerateSlug';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import { ConfirmAlert, useAlert } from 'src/hooks/alerts';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
export default function index() {
  let rerender: boolean = true;
  const { user } = useSelector((state: RootState) => state.auth);
  const [responses, setResponses] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const alert = useAlert();
  const [pagination, setPagination] = useState<any>({
    page: 1,
    is_active: '',
    product_type: ''
  });
  useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);
  const GetItems = useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('products', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.products;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('products/update_is_active/' + id);
      setResponses((res) => {
        return res.map((e) => {
          const matched = e.id === id;
          return {
            ...e,
            is_active: matched ? (e.is_active ? 0 : 1) : e.is_active
          };
        });
      });

      await GetItems();
      
      alert.show({
        text: data?.message,
        visible: true
      });
    } catch (error: AxiosError | any) {
      alert.show({
        text: error.response?.data?.message,
        visible: true
      });
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);
      const { data } = await axiosInstance.post('products/delete/' + id);
      await GetItems();
      alert.show({
        text: data?.message,
        visible: true,
        type: 'alert'
      });
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = useMemo(
    () => (
      <Table
        RenderHead={() => (
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>SKU</th>
            <th>Min price</th>
            <th>Display price</th>
            <th>Type</th>
            <th>Status</th>
            <th>Latest update</th>
            <th>Actions</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {responses.map((response: any, i) => {
              const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

              return (
                <tr key={i}>
                  <th>
                    <div>
                      <Image
                        src={response.image}
                        className="w-10 h-10 rounded object-cover"
                      />
                    </div>
                  </th>
                  <th>{response.name || 'N/A'}</th>
                  <th>{response.sku || 'N/A'}</th>
                  <th>{CurrencyFormatter(response.min_price)}</th>
                  <th>{CurrencyFormatter(response.price)}</th>
                  <th>{response.product_type}</th>
                  <th>
                    <div className="inline-flex items-center gap-4">
                      <Switcher
                        checked={!!response.is_active}
                        onChange={function (checked: boolean): void {
                          ChangeStatus(response.id);
                        }}
                      />
                      <DotStatus active={!!response.is_active} />
                    </div>
                  </th>
                  <td>
                    <SharedTime date={response.updated_at || response.created_at} />
                  </td>
                  <td>
                    <div className="inline-flex gap-2 items-center">
                      <Link
                        to={'/products/' + GenerateSlug(response.id + ' ' + response.name)}
                        className="btn-with-icon outline-btn justify-start"
                      >
                        <Icon
                          icon="ri:eye-line"
                          width={19}
                        />
                      </Link>
                      {user?.user_type === 'super_admin' && (
                        <button
                          className="btn-with-icon outline-btn text-start"
                          onClick={() => setDeleteConfirm(true)}
                        >
                          <span>Delete</span>
                        </button>
                      )}
                    </div>
                  </td>

                  <ConfirmAlert
                    visible={deleteConfirm}
                    requestClose={function (): void {
                      setDeleteConfirm(false);
                    }}
                    handleConfirm={function (): void {
                      DeleteItem(response.id);
                      setDeleteConfirm(false);
                    }}
                  />
                </tr>
              );
            })}
          </>
        )}
        isEmpty={!responses?.length}
        title="Products table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    ),
    [responses, isUpdating, pagination]
  );
  return (
    <div className="p-8 pt-0 space-y-4">
      <div>
        <div className="flex gap-2 flex-wrap justify-end">
          <Link
            to="/products/add"
            className="btn-with-icon outline-btn text-neutral-600 shrink-0"
          >
            <Icon
              icon="material-symbols:add-rounded"
              width={18}
              height={18}
            />
            <span>New product</span>
          </Link>
        </div>
      </div>
      {MEMO_TABLE}
    </div>
  );
}
