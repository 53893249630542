import { Icon } from '@iconify/react';
import React, { useMemo, useState, useRef, useCallback, useEffect, Fragment } from 'react';
import Image from 'src/components/shared/Image';
import Table from 'src/components/shared/tables';
import { Link } from 'react-router-dom';
import DotStatus from 'src/components/shared/DotStatus';
import SharedTime from 'src/components/shared/SharedTime';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { pick } from 'lodash';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import GenerateSlug from 'src/helper/GenerateSlug';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import Modal from 'src/components/shared/Modal';

export default function ActivityLogs() {
  let rerender: boolean = true;
  const [responses, setResponses] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [showVisible, setShowVisible] = React.useState<any | undefined>();
  const [pagination, setPagination] = useState<any>({
    page: 1,
    is_active: '',
    search_key: '',
    product_type: ''
  });
  useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);
  const GetItems = useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'user_type',
        'gender'
      ]);

      const { data } = await axiosInstance.get('request_logs', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.request_logs;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('users/update_is_active/' + id);
      setResponses((res) => {
        return res.map((e) => {
          const matched = e.id === id;
          return {
            ...e,
            is_active: matched ? (e.is_active ? 0 : 1) : e.is_active
          };
        });
      });
      await GetItems();
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('users/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = useMemo(
    () => (
      <Table
        RenderHead={() => (
          <tr>
            <th>Method</th>
            <th>Status</th>
            <th>URL</th>
            <th>IP</th>
            <th>Created date</th>

            <th>Actions</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {responses.map((response: any, i) => (
              <tr key={i}>
                <th>{response.request_method || 'N/A'}</th>
                <th>{response.status || 'N/A'}</th>
                <th>{response.request_url || 'N/A'}</th>
                <th>{response.ip || 'N/A'}</th>

                <td>
                  <SharedTime date={response.created_at} />
                </td>
                <td>
                  <button
                    className="btn-with-icon outline-btn !text-neutral-600 shrink-0"
                    onClick={() => {
                      setShowVisible(response);
                      setVisible(true);
                    }}
                  >
                    <Icon
                      icon="ri:eye-line"
                      width={18}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </>
        )}
        isEmpty={!responses?.length}
        title="Logs table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    ),
    [responses, isUpdating, pagination]
  );
  return (
    <Fragment>
      <div className="p-8 pt-0 space-y-4">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setShowVisible(undefined);
        }}
        title="Request log details"
        size="max-w-screen-md"
      >
        <Fragment>
          <div>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-px p-px bg-neutral-200">
              <li className="bg-white p-4 space-y-1 sm:col-span-2">
                <details className="p-3 px-4 rounded border border-neutral-200">
                  <summary className="text-sm text-neutral-600 font-medium cursor-pointer">
                    Request
                  </summary>
                  <p className="text-base font-semibold mt-3">{showVisible?.request}</p>
                </details>
              </li>
              <li className="bg-white p-4 space-y-1 sm:col-span-2">
                <details className="p-3 px-4 rounded border border-neutral-200">
                  <summary className="text-sm text-neutral-600 font-medium cursor-pointer">
                    Response
                  </summary>
                  <div className="text-base font-semibold mt-3 break-words">
                    {showVisible?.response}
                  </div>
                </details>
              </li>
              <li className="bg-white p-4 space-y-1 sm:col-span-2">
                <details className="p-3 px-4 rounded border border-neutral-200">
                  <summary className="text-sm text-neutral-600 font-medium cursor-pointer">
                    User agent
                  </summary>
                  <p className="text-base font-semibold mt-3">{showVisible?.user_agent}</p>
                </details>
              </li>
              <li className="bg-white p-4 space-y-1">
                <p className="text-sm text-neutral-500 font-medium">Status</p>
                <p className="text-base text-neutral-800 font-semibold">{showVisible?.status}</p>
              </li>
              <li className="bg-white p-4 space-y-1">
                <p className="text-sm text-neutral-500 font-medium">Method</p>
                <p className="text-base text-neutral-800 font-semibold">
                  {showVisible?.request_method}
                </p>
              </li>
              <li className="bg-white p-4 space-y-1">
                <p className="text-sm text-neutral-500 font-medium">IP</p>
                <p className="text-base text-neutral-800 font-semibold">{showVisible?.ip}</p>
              </li>
              <li className="bg-white p-4 space-y-1">
                <p className="text-sm text-neutral-500 font-medium">URL</p>
                <p className="text-base text-neutral-800 font-semibold">
                  {showVisible?.request_url}
                </p>
              </li>

              <li className="bg-white p-4 space-y-1 sm:col-span-2">
                <p className="text-sm text-neutral-500 font-medium">Created at</p>
                <p>
                  <SharedTime date={showVisible?.created_at} />
                </p>
              </li>
            </ul>
          </div>
        </Fragment>
      </Modal>
    </Fragment>
  );
}
