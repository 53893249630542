import React, { FC, useState } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/index';
import SharedTime from 'src/components/shared/SharedTime';
import Select from 'src/components/shared/Select';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { ConfirmAlert } from 'src/hooks/alerts';
import Password from 'src/components/shared/Password';

const types: string[] = ['super_admin', 'admin', 'salesman', 'accountant'];

const Admins: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [roles, setRoles] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);
  const { user } = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems(), GetCities()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetRoles = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('roles', { params: { is_active: 1 } });
      setRoles(data?.result?.roles);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetCities = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('cities', { params: { is_active: 1 } });
      setCities(data?.result?.cities);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'user_type',
        'gender'
      ]);

      const { data } = await axiosInstance.get('admins', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.admins;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('admins/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('admins/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>E-mail address</th>
              <th>User type</th>
              <th>Role</th>
              <th>Status</th>
              <th>Latest update</th>
              <th>Actions</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>
                    <td>{item.mobile || 'N/A'}</td>
                    <td>{item.email || 'N/A'}</td>
                    <td>{item.user_type || 'N/A'}</td>
                    <td>{item.role?.name || 'N/A'}</td>
                    <td>
                      <div className="inline-flex items-center gap-4">
                        <Switcher
                          checked={!!item.is_active}
                          onChange={function (checked: boolean): void {
                            ChangeStatus(item.id);
                          }}
                        />
                        <DotStatus active={!!item.is_active} />
                      </div>
                    </td>
                    <td>
                      <SharedTime date={item.updated_at || item.created_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2 items-center">
                        <button
                          className="btn-with-icon outline-btn justify-start"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          <Icon
                            icon="ri:eye-line"
                            width={19}
                          />
                        </button>
                        {user?.user_type === 'super_admin' && (
                          <button
                            className="btn-with-icon outline-btn text-start"
                            onClick={() => setDeleteConfirm(true)}
                          >
                            <span>Delete</span>
                          </button>
                        )}
                      </div>
                    </td>
                    <ConfirmAlert
                      visible={deleteConfirm}
                      requestClose={function (): void {
                        setDeleteConfirm(false);
                      }}
                      handleConfirm={function (): void {
                        DeleteItem(item.id);
                        setDeleteConfirm(false);
                      }}
                    />
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Admins table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-8 pt-0 space-y-4 grid">
        <div className="flex items-center justify-between flex-wrap">
          <div></div>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon outline-btn shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New admin</span>
            </button>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        title="New admin"
        handleClose={() => {
          setVisible(false);
        }}
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
          roles={roles}
          cities={cities}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update admin"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{
            ...editItem,
            role_id: editItem?.role?.id,
            city_ids: editItem?.cities?.map((e: any) => e.id)
          }}
          roles={roles}
          cities={cities}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  password: string | undefined;
  role_id: string | undefined;
  city_ids: string | undefined;
  user_type: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching,
  roles,
  cities
}: {
  closeModal: any;
  reFetching: any;
  roles: any[];
  cities: any[];
}): JSX.Element => {
  const globalValues = {
    name: undefined,
    email: undefined,
    mobile: undefined,
    password: undefined,
    role_id: undefined,
    city_ids: undefined,
    user_type: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('admins/add', {
        ...values,
        role_id: values.user_type === 'admin' ? values.role_id : undefined,
        city_ids: ['accountant', 'salesman'].includes(values.user_type)
          ? values.city_ids
          : undefined
      });
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">Email address</label>
        <input
          type="email"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.email}
          onChange={(e) => handleChange('email', e)}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Phone number</label>
        <input
          type="tel"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.mobile}
          onChange={(e) => handleChange('mobile', e)}
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Password</label>
        <Password
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.password}
          name="password"
          onChange={formik.handleChange}
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">User type</label>
        <select
          name="user-type"
          id="user-type"
          className="form-select form-outline"
          value={formik.values.user_type}
          defaultValue={''}
          onChange={(e) => handleChange('user_type', e)}
          required
        >
          <option
            value={''}
            disabled
          >
            -- select --
          </option>
          {types
            .filter((e) => e !== 'all')
            .map((type, index: string | number) => (
              <option
                value={type}
                key={index}
              >
                {type}
              </option>
            ))}
        </select>

        {errors?.user_type ? <span className="form-error">{errors?.user_type}</span> : null}
      </div>

      {/* {formik.values.user_type === 'admin' ? (
        <div className="form-group">
          <label className="form-label">Role</label>
          <Select
            type={'single'}
            options={roles}
            value={formik.values.role_id}
            onSelect={function (value: any): void {
              formik.setFieldValue('role_id', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
          {errors?.role_id ? <span className="form-error">{errors?.role_id}</span> : null}
        </div>
      ) : null} */}
      {['accountant', 'salesman'].includes(formik.values.user_type) ? (
        <div className="form-group">
          <label className="form-label">Cities</label>
          <Select
            type={'multi'}
            options={cities}
            value={formik.values.city_ids}
            onSelect={function (value: any): void {
              formik.setFieldValue('city_ids', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
          {errors?.city_ids ? <span className="form-error">{errors?.city_ids}</span> : null}
        </div>
      ) : null}

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema,
  cities,
  roles
}: {
  closeModal: any;
  reFetching: any;
  cities: any[];
  roles: any[];
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('admins/update', {
        ...values,
        role_id: values.user_type === 'admin' ? values.role_id : undefined,
        city_ids: ['accountant', 'salesman'].includes(values.user_type)
          ? values.city_ids
          : undefined
      });
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">Email address</label>
        <input
          type="email"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.email}
          onChange={(e) => handleChange('email', e)}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Phone number</label>
        <input
          type="tel"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.mobile}
          onChange={(e) => handleChange('mobile', e)}
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Password</label>
        <Password
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.password}
          onChange={(e) => handleChange('password', e)}
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">User type</label>
        <select
          name="user-type"
          id="user-type"
          className="form-select form-outline"
          value={formik.values.user_type}
          defaultValue={''}
          onChange={(e) => handleChange('user_type', e)}
          required
        >
          <option
            value={''}
            disabled
          >
            -- select --
          </option>
          {types
            .filter((e) => e !== 'all')
            .map((type, index: string | number) => (
              <option
                value={type}
                key={index}
              >
                {type}
              </option>
            ))}
        </select>

        {errors?.user_type ? <span className="form-error">{errors?.user_type}</span> : null}
      </div>

      {/* {formik.values.user_type === 'admin' ? (
        <div className="form-group">
          <label className="form-label">Role</label>
          <Select
            type={'single'}
            options={roles}
            value={formik.values.role_id}
            onSelect={function (value: any): void {
              formik.setFieldValue('role_id', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
          {errors?.role_id ? <span className="form-error">{errors?.role_id}</span> : null}
        </div>
      ) : null} */}
      {['accountant', 'salesman'].includes(formik.values.user_type) ? (
        <div className="form-group">
          <label className="form-label">Cities</label>
          <Select
            type={'multi'}
            options={cities}
            value={formik.values.city_ids}
            onSelect={function (value: any): void {
              formik.setFieldValue('city_ids', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
          {errors?.city_ids ? <span className="form-error">{errors?.city_ids}</span> : null}
        </div>
      ) : null}

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

export default Admins;
