import React, { FC, useCallback } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/index';
import SharedTime from 'src/components/shared/SharedTime';
import Select from 'src/components/shared/Select';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import { names } from 'src/helper/routes';

const accesses: string[] = ['show', 'edit', 'update', 'delete'];

const Permissions: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('roles', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.roles;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('roles/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('roles/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Permissions</th>
              <th>Latest update</th>
              <th>Actions</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>
                    <td>
                      <div className="flex gap-2">
                        {item.permissions?.slice(0, 2)?.map((e: string, i: number) => (
                          <span
                            className="btn-with-icon outline-btn"
                            key={i}
                          >
                            {e}
                          </span>
                        ))}
                        {item.permissions?.length > 2 ? (
                          <span className="btn-with-icon outline-btn">
                            +{item.permissions?.length - 2}
                          </span>
                        ) : null}
                      </div>
                    </td>

                    <td>
                      <SharedTime date={item.updated_at || item.created_at} />
                    </td>
                    <td>
                      <Dropdown>
                        <>
                          <li>
                            <button
                              className="btn-with-icon !text-neutral-600 justify-start"
                              onClick={() => {
                                setEditItem(item);
                                setEditVisible(true);
                              }}
                            >
                              <Icon
                                icon="ph:pencil-simple-line-duotone"
                                width={18}
                              />
                              <span>Update</span>
                            </button>
                          </li>
                          <li>
                            <button
                              className="btn-with-icon !text-neutral-600 text-start"
                              onClick={() => DeleteItem(item.id)}
                            >
                              <Icon
                                icon="fluent:delete-32-regular"
                                width={18}
                              />
                              <span>Delete</span>
                            </button>
                          </li>
                        </>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Permissions table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-8 pt-0 space-y-4 grid">
        <div className="flex items-center justify-between flex-wrap">
          <div></div>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon outline-btn shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New permission</span>
            </button>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        title="New permission"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update permission"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{
            ...editItem
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  permissions: string[] | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    permissions: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('roles/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const UpdatePermissions = useCallback(
    function (name: string, access: string) {
      const accessName = access + ' ' + name;

      if (formik.values.permissions?.some((ev: string) => ev === accessName)) {
        formik.setFieldValue(
          'permissions',
          formik.values.permissions.filter((ev: string) => ev !== accessName)
        );
        return;
      }

      formik.setFieldValue(
        'permissions',
        new Array().concat(
          formik.values.permissions || [],
          formik.values.permissions?.includes('show ' + name)
            ? accessName
            : ['show ' + name, accessName]
        )
      );
    },
    [formik.values.permissions]
  );

  const SelectAllPermissions = useCallback(
    function () {
      let result: string[] = [],
        collectPermissions: any[];

      collectPermissions = new Array().concat(
        ...names.map((name: string) => {
          let item: string[];

          item = accesses.map((access: string) => access + ' ' + name);
          return item;
        })
      );

      formik.setFieldValue('permissions', collectPermissions);
    },
    [formik.values.permissions]
  );
  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>

      <div className="form-group">
        <div className="flex items-center gap-4 justify-between">
          <p className="form-label">Permissions</p>
          <button
            className="btn-with-icon  !text-xs bg-secondary"
            type="button"
            onClick={SelectAllPermissions}
          >
            Select all
          </button>
        </div>
        <ul className="bg-neutral-200 space-y-px p-px">
          {names.map((name: string, i) => (
            <li
              key={i}
              className="p-3 bg-white"
            >
              <div className="form-group">
                <p className="form-label">{name}</p>
                <div className="inline-flex gap-4 flex-wrap">
                  {accesses.map((access: string, index: number) => {
                    const accessName = access + ' ' + name;
                    return (
                      <div className="flex gap-2 items-center">
                        <Switcher
                          checked={formik.values.permissions?.some(
                            (ev: string) => ev === accessName
                          )}
                          onChange={function (checked: boolean): void {
                            UpdatePermissions(name, access);
                          }}
                        />
                        <span>{access}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('roles/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const UpdatePermissions = useCallback(
    function (name: string, access: string) {
      const accessName = access + ' ' + name;

      if (formik.values.permissions?.some((ev: string) => ev === accessName)) {
        formik.setFieldValue(
          'permissions',
          formik.values.permissions.filter((ev: string) => ev !== accessName)
        );
        return;
      }

      formik.setFieldValue(
        'permissions',
        new Array().concat(
          formik.values.permissions || [],
          formik.values.permissions?.includes('show ' + name)
            ? accessName
            : ['show ' + name, accessName]
        )
      );
    },
    [formik.values.permissions]
  );

  const SelectAllPermissions = useCallback(
    function () {
      let result: string[] = [],
        collectPermissions: any[];

      collectPermissions = new Array().concat(
        ...names.map((name: string) => {
          let item: string[];

          item = accesses.map((access: string) => access + ' ' + name);
          return item;
        })
      );

      formik.setFieldValue('permissions', collectPermissions);
    },
    [formik.values.permissions]
  );

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>

      <div className="form-group">
        <div className="flex items-center gap-4 justify-between">
          <p className="form-label">Permissions</p>
          <button
            className="btn-with-icon  !text-xs bg-secondary"
            type="button"
            onClick={SelectAllPermissions}
          >
            Select all
          </button>
        </div>
        <ul className="bg-neutral-200 space-y-px p-px">
          {names.map((name: string, i) => (
            <li
              key={i}
              className="p-3 bg-white"
            >
              <div className="form-group">
                <p className="form-label">{name}</p>
                <div className="inline-flex gap-4 flex-wrap">
                  {accesses.map((access: string, index: number) => {
                    const accessName = access + ' ' + name;
                    return (
                      <div className="flex gap-2 items-center">
                        <Switcher
                          checked={formik.values.permissions?.some(
                            (ev: string) => ev === accessName
                          )}
                          onChange={function (checked: boolean): void {
                            UpdatePermissions(name, access);
                          }}
                        />
                        <span>{access}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

export default Permissions;
