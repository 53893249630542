import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputFile from 'src/components/shared/InputFile';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';
import useTitle from 'src/hooks/useTitle';

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  desc: string | undefined;
  alt_desc: string | undefined;
  price: number | undefined;
  min_price: number | undefined;
  sku: string | undefined;
  weight: string | undefined;
  image: File | undefined;
}

export default function Update() {
  let rerender = true;
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    desc: undefined,
    alt_desc: undefined,
    price: undefined,
    min_price: undefined,
    sku: undefined,
    weight: undefined,
    image: undefined
  } satisfies FormInterface;
  const { updateTitle } = useTitle();
  const [initialValues, setInitialValues] = React.useState<FormInterface>(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { slug }: any = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (rerender) {
      GetSelectedItem();
      rerender = false;
    }
  }, []);

  async function GetSelectedItem() {
    try {
      const id = slug?.split('-')?.[0];
      const { data } = await axiosInstance.get('/products/' + id);
      const item = data?.result?.product;
      updateTitle({ title: item.name });
      setInitialValues(item);
    } catch (error) {
      console.log('error while sending request..');
    } finally {
      setIsLoading(false);
    }
  }

  const HandleSubmission = useCallback(async function (values: any, helpers: any) {
    try {
      setDisabled(true);
      setErrors(undefined);
      let fd: FormData = new FormData();

      for (const key in values) {
        const item = values[key];

        if (item) {
          if (key === 'weight') {
            if (values.product_type === 'weight') {
              fd.append(key, item);
            } else {
              fd.delete(key);
            }
          } else if (key === 'image') {
            if (item instanceof File) {
              fd.append(key, item, item.name);
            } else {
              fd.delete(key);
            }
          } else {
            fd.append(key, item);
          }
        }
      }

      const { data } = await axiosInstance.post('products/update', fd);
      helpers.resetForm();
      navigate(-1);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        setErrors(err.message);
      } else {
        setErrors('There is something went wrong while sending request.');
      }
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { values, handleSubmit, handleChange, setFieldValue }
  } = useForm({
    initialValues,
    submitHandler: HandleSubmission
  });

  return (
    <div className="p-8 pt-0 relative h-full">
      {isLoading ? (
        <LoadingComponent position="absolute" />
      ) : (
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label className="form-label">Image</label>
            <InputFile
              defaultValue={values.image instanceof File ? values.image : undefined}
              onValueChange={function (value: any): void {
                setFieldValue('image', value);
              }}
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">Primary name</label>
              <input
                type="text"
                className="form-input"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                placeholder="..."
                value={values.name}
                name="name"
                onChange={handleChange}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">Secondary name</label>
              <input
                type="text"
                className="form-input"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                placeholder="..."
                value={values.alt_name}
                name="alt_name"
                onChange={handleChange}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">SKU</label>
            <input
              type="text"
              className="form-input"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="true"
              placeholder="..."
              value={values.sku}
              name="sku"
              onChange={handleChange}
            />
            {errors?.sku ? <span className="form-error">{errors?.sku}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">Product type</label>
            <div>
              <ul className="space-y-2 !mt-4">
                <li>
                  <div className="inline-flex gap-3 items-center">
                    <input
                      type="radio"
                      required
                      autoComplete="off"
                      name="product-type"
                      className="form-radio"
                      onChange={() => setFieldValue('product_type', 'normal')}
                      value="normal"
                      checked={values.product_type === 'normal'}
                      id="normal"
                    />
                    <label
                      className="form-label"
                      htmlFor="normal"
                    >
                      Normal
                    </label>
                  </div>
                </li>
                <li>
                  <div className="inline-flex gap-3 items-center">
                    <input
                      type="radio"
                      required
                      autoComplete="off"
                      name="product-type"
                      className="form-radio"
                      onChange={() => setFieldValue('product_type', 'weight')}
                      value="weight"
                      checked={values.product_type === 'weight'}
                      id="weight"
                    />
                    <label
                      className="form-label"
                      htmlFor="weight"
                    >
                      Weight
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            {errors?.product_type ? (
              <span className="form-error">{errors?.product_type}</span>
            ) : null}
          </div>

          {values.product_type === 'weight' && (
            <div className="form-group">
              <label className="form-label">Display weight (KG)</label>
              <input
                type="number"
                className="form-input"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                value={values.weight}
                name="weight"
                onChange={handleChange}
                placeholder="0"
                              step="any"

              />
              {errors?.weight ? <span className="form-error">{errors?.weight}</span> : null}
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">
                Min price (SAR){' '}
                {values.product_type === 'weight' && (
                  <span className="align-middle text-sm text-red-500">Per kilo</span>
                )}
              </label>
              <input
                type="number"
                className="form-input"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                value={values.min_price}
                name="min_price"
                onChange={handleChange}
                placeholder="0.00 SAR"
                              step="any"

              />
              {errors?.min_price ? <span className="form-error">{errors?.min_price}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">
                Display price (SAR){' '}
                {values.product_type === 'weight' && (
                  <span className="align-middle text-sm text-red-500">Per kilo</span>
                )}
              </label>
              <input
                type="number"
                className="form-input"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                value={values.price}
                name="price"
                onChange={handleChange}
                placeholder="0.00 SAR"
                              step="any"

              />
              {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
            </div>
          </div>

          <p className="text-sm p-3 rounded font-medium text-neutral-600 bg-neutral-200">
            The price does not include VAT
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">Primary description</label>
              <textarea
                className="form-textarea"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                placeholder="..."
                value={values.desc}
                name="desc"
                onChange={handleChange}
              ></textarea>

              {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">Secondary description</label>
              <textarea
                className="form-textarea"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="true"
                placeholder="..."
                value={values.alt_desc}
                name="alt_desc"
                onChange={handleChange}
              ></textarea>
              {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
            </div>
          </div>
          <div>
            <div className="inline-flex gap-2 flex-wrap">
              <button
                className="btn-with-icon bg-primary"
                type="submit"
                disabled={disabled}
              >
                {disabled ? (
                  <Icon
                    icon="svg-spinners:3-dots-fade"
                    width={20}
                  />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
