import { Icon } from '@iconify/react';
import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import Image from 'src/components/shared/Image';
import Table from 'src/components/shared/tables';
import { Link } from 'react-router-dom';
import DotStatus from 'src/components/shared/DotStatus';
import SharedTime from 'src/components/shared/SharedTime';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { pick } from 'lodash';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import GenerateSlug from 'src/helper/GenerateSlug';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from 'src/components/shared/MiniDashboardSingleCard';
import Select from 'src/components/shared/Select';
import Modal from 'src/components/shared/Modal';
import { Formik, FormikHelpers } from 'formik';
import useForm from 'src/hooks/useForm';
import { isNonNullChain } from 'typescript';

const globalCards: Array<ItemInterface> = [
  {
    icon: 'la:file-invoice-dollar',
    color: 'text-sky-500',
    title: 'Credit',
    key: 'credit',
    value: 0
  },
  {
    icon: 'uil:money-withdrawal',
    color: 'text-sky-500',
    title: 'Bank transfer',
    key: 'bank',
    value: 0
  },
  {
    icon: 'ion:wallet-outline',
    color: 'text-sky-500',
    title: 'Cash',
    key: 'cash',
    value: 0
  }
];

const payments = ['credit', 'bank', 'cash'];
export const invoiceStatus = [
  'all',
  'quotation',
  'delivery_receipt',
  'vat_invoice',
  'hold',
  'closed',
  'cancelled'
];
export default function index() {
  let rerender: boolean = true;
  const [responses, setResponses] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cards, setCards] = useState<Array<ItemInterface>>(globalCards);
  const [stores, setStores] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [admins, setAdmins] = React.useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    from: new Date().toISOString().substring(0, 10),
    is_active: undefined,
    search_key: undefined,
    to: undefined,
    city_id: undefined,
    region_id: undefined,
    store_id: undefined,
    branch_id: undefined,
    admin_id: undefined,
    payment_method: undefined,
    status: undefined,
    overdue: 0
  });
  useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems(), GetRegions(), GetCities(), GetStores(), GetBranches(), GetAdmins()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetAdmins = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('admins', {
        params: { user_type: 'salesman' }
      });
      setAdmins(data?.result?.admins);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetRegions = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('regions', { params: { is_active: 1 } });
      setRegions(data?.result?.regions);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetCities = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('cities', { params: { is_active: 1 } });
      setCities(data?.result?.cities);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetStores = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('stores', { params: { is_active: 1 } });
      setStores(data?.result?.stores);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetBranches = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('branches', { params: { is_active: 1 } });
      setBranches(data?.result?.branches);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetItems = useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);

      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'from',
        'to',
        'city_id',
        'region_id',
        'store_id',
        'branch_id',
        'admin_id',
        'payment_method',
        'status',
        'overdue'
      ]);

      const { data } = await axiosInstance.get('invoices', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.invoices;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
      setCards((_cards) => {
        return _cards.map((card) => {
          const item = data.result?.summary?.[card?.key as string];
          if (item) {
            return {
              ...card,
              value: item.count + ' - ' + CurrencyFormatter(item.total)
            };
          }
          return card;
        });
      });
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('invoices/update_is_active/' + id);
      setResponses((res) => {
        return res.map((e) => {
          const matched = e.id === id;
          return {
            ...e,
            is_active: matched ? (e.is_active ? 0 : 1) : e.is_active
          };
        });
      });
      await GetItems();
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('invoices/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const CloseInvoice = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.post('invoices/close/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DownloadInvoice = useCallback(async function (id: string) {
    try {
      setIsUpdating(true);
      let link = document.createElement('a'),
        url: string;

      const { data } = await axiosInstance.post(
        'invoices/download/' + id,
        {},
        {
          responseType: 'blob'
        }
      );

      url = URL.createObjectURL(new Blob([data]));

      link.href = url;
      link.download = GenerateSlug(id + ' ' + new Date().getTime() + '.pdf');

      link.click();
      URL.revokeObjectURL(url);
      link.remove();

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ExportInvoices = useCallback(async function () {
    try {
      setIsUpdating(true);
      let link = document.createElement('a'),
        url: string;

      const { data } = await axiosInstance.get('invoices/export_excel', {
        responseType: 'blob'
      });

      url = URL.createObjectURL(new Blob([data]));

      link.href = url;
      link.download = GenerateSlug('invoices-export' + new Date().getTime() + '.xlsx');

      link.click();
      URL.revokeObjectURL(url);
      link.remove();

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ClearAll = useCallback(function () {
    setPagination(() => ({
      page: 1
    }));
    GetItems();
  }, []);

  const filterCities = useMemo(() => {
    return cities.filter((ev: any) => {
      setPagination((values: any) => ({
        ...values,
        city_id: undefined
      }));

      return pagination.region_id
        ? ev.region?.id?.toString() === pagination.region_id?.toString()
        : ev;
    });
  }, [pagination.region_id, regions, cities]);

  const filterBranches = useMemo(() => {
    return branches.filter((ev: any) => {
      setPagination((values: any) => ({
        ...values,
        branch_id: undefined
      }));

      return pagination.store_id
        ? ev.store?.id?.toString() === pagination.store_id?.toString()
        : ev;
    });
  }, [pagination.store_id, branches, stores]);

  const DisplayPayments = ({ type }: { type: string }) => {
    switch (type) {
      case 'credit':
        return (
          <Icon
            icon="fluent-emoji:credit-card"
            width={20}
            height={20}
          />
        );
        break;
      case 'bank':
        return (
          <Icon
            icon="fluent-emoji:dollar-banknote"
            width={20}
            height={20}
          />
        );
        break;
      case 'cash':
        return (
          <Icon
            icon="noto:money-with-wings"
            width={20}
            height={20}
          />
        );
        break;
      default:
        return <></>;
        break;
    }
  };

  const MEMO_TABLE = useMemo(() => {
    return (
      <Table
        RenderHead={() => (
          <tr>
            <th>Invoice no.</th>
            <th>Salesman</th>
            <th>Store</th>
            <th>Branch</th>
            <th>Region</th>
            <th>City</th>
            {/* <th>Client</th> */}
            {/* <th>Payment type</th> */}
            <th>Vat</th>
            <th>Total</th>
            <th>Status</th>
            <th>Invoice date</th>
            <th>Actions</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {responses.map((response: any, i) => {
              return (
                <tr key={i}>
                  <th>{response.invoice_no || 'N/A'}</th>
                  <th>{response.admin?.name || 'N/A'}</th>
                  <th>{response.store?.name || 'N/A'}</th>
                  <th>{response.branch?.name || 'N/A'}</th>
                  <th>{response.branch?.region?.name || 'N/A'}</th>
                  <th>{response.branch?.city?.name || 'N/A'}</th>

                  <th>{CurrencyFormatter(response.vat)}</th>
                  <th>
                    <div
                      className="inline-flex gap-2 items-center"
                      title={response.payment_method}
                    >
                      <span>{CurrencyFormatter(response.total)}</span> -
                      <DisplayPayments type={response.payment_method} />
                    </div>
                  </th>
                  <th>
                    <p className="btn-with-icon w-fit font-medium bg-neutral-100 !text-neutral-600">
                      {response.status}
                    </p>
                  </th>
                  <td>
                    <SharedTime date={response.invoice_date} />
                  </td>
                  <td>
                    <div className="inline-flex items-center gap-2">
                      <Link
                        to={'/invoices/' + response.id}
                        className="btn-with-icon outline-btn !text-neutral-600 shrink-0"
                      >
                        <Icon
                          icon={'ri:eye-line'}
                          width={18}
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        )}
        isEmpty={!responses?.length}
        title="Invoices table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <div className="p-8 pt-0 space-y-4">
      <details
        open
        className="py-3 px-4 rounded border border-neutral-200 bg-white shadow-2xl shadow-neutral-600/5"
      >
        <summary className="text-sm font-medium cursor-pointer">Filter</summary>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-end mt-4">
          <div className="form-group">
            <p className="form-label">Salesman</p>
            <Select
              type={'single'}
              options={admins}
              value={pagination.admin_id}
              onSelect={function (value: any): void {
                setPagination((values: any) => ({
                  ...values,
                  admin_id: value
                }));
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />
          </div>
          <div className="form-group">
            <p className="form-label">Region</p>
            <Select
              type={'single'}
              options={regions}
              value={pagination.region_id}
              onSelect={function (value: any): void {
                setPagination((values: any) => ({
                  ...values,
                  region_id: value
                }));
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />
          </div>
          <div className="form-group">
            <p className="form-label">City</p>
            <Select
              type={'single'}
              options={filterCities}
              value={pagination.city_id}
              onSelect={function (value: any): void {
                setPagination((values: any) => ({
                  ...values,
                  city_id: value
                }));
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />
          </div>
          <div className="form-group">
            <p className="form-label">Store</p>
            <Select
              type={'single'}
              options={stores}
              value={pagination.store_id}
              onSelect={function (value: any): void {
                setPagination((values: any) => ({
                  ...values,
                  store_id: value
                }));
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />
          </div>
          <div className="form-group">
            <p className="form-label">Branch</p>
            <Select
              type={'single'}
              options={filterBranches}
              value={pagination.branch_id}
              onSelect={function (value: any): void {
                setPagination((values: any) => ({
                  ...values,
                  branch_id: value
                }));
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />
          </div>
          <div className="form-group">
            <p className="form-label">Payment type</p>
            <select
              name="filter-payment-method"
              id="filter-payment-method"
              className="form-select"
              defaultValue={''}
              value={pagination.payment_method}
              onChange={function ({ target }: { target: HTMLSelectElement }): void {
                setPagination((values: any) => ({
                  ...values,
                  payment_method: target.value
                }));
              }}
            >
              <option value=""> All</option>

              {payments.map((payment, idx) => (
                <option
                  value={payment}
                  key={idx}
                >
                  {payment}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <p className="form-label">Status</p>
            <select
              name="invoice-status"
              id="invoice-status"
              className="form-select"
              defaultValue={''}
              value={pagination.status}
              onChange={function ({ target }: { target: HTMLSelectElement }): void {
                setPagination((values: any) => ({
                  ...values,
                  status: target.value
                }));
              }}
            >
              <option
                value=""
                selected
                disabled
              >
                Status
              </option>

              {invoiceStatus?.map((ev: string, index: string | number) => {
                return (
                  <option
                    value={ev}
                    key={index}
                  >
                    {ev}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <p className="form-label">From</p>
            <input
              type="date"
              name="date-filter"
              id="date-filter"
              className="form-input !inline-block"
              value={pagination.from}
              onChange={(e) => {
                setPagination((values: any) => ({
                  ...values,
                  from: (e.target as HTMLInputElement).value
                }));
              }}
            />
          </div>
          <div className="form-group">
            <p className="form-label">To</p>
            <input
              type="date"
              name="date-filter"
              id="date-filter"
              className="form-input !inline-block"
              value={pagination.to}
              min={pagination.from}
              onChange={(e) => {
                setPagination((values: any) => ({
                  ...values,
                  to: (e.target as HTMLInputElement).value
                }));
              }}
            />
          </div>
          <div className="form-group">
            <div className="inline-flex gap-2">
              <button
                className="btn-with-icon bg-primary"
                onClick={() => GetItems(pagination)}
              >
                <span>Apply</span>
              </button>
              <button
                className="btn-with-icon bg-neutral-100 !text-neutral-600"
                onClick={ClearAll}
              >
                <span>Clear</span>
              </button>
            </div>
          </div>
        </div>
      </details>

      <div className="grid grid-wrapper gap-4">
        {cards.map((card: ItemInterface, index: number) => (
          <MiniDashboardSingleCard item={card} />
        ))}
      </div>

      <div className="flex gap-2 flex-wrap justify-end items-center ">
        <div className="flex-1">
          <div className="inline-flex gap-4 items-center">
            <Switcher
              checked={!!pagination.overdue}
              onChange={() => {
                GetItems({
                  ...pagination,
                  overdue: !!pagination.overdue ? 0 : 1
                });
              }}
            />
            <p className="font-medium">Overdue invoices</p>
          </div>
        </div>
        <Link
          to="/invoices/new"
          className="btn-with-icon outline-btn text-neutral-600 shrink-0"
        >
          <Icon
            icon="material-symbols:add-rounded"
            width={18}
            height={18}
          />
          <span>New invoice</span>
        </Link>
        <button
          className="btn-with-icon bg-primary shrink-0"
          onClick={() => ExportInvoices()}
        >
          <Icon
            icon="fa6-regular:file-excel"
            width={18}
            height={18}
          />
          <span>Download</span>
        </button>
      </div>

      {MEMO_TABLE}
    </div>
  );
}
