import { Popover } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { ReactNode } from 'react';

type Position = 'TOP' | 'BOTTOM' | 'RIGHT' | 'LEFT';

export default function Dropdown({
  children,
  btnTitle,
  btnIcon,
  position = 'RIGHT'
}: {
  children: ReactNode;
  btnTitle?: string;
  btnIcon?: string;
  position?: Position;
}) {
  const positions: Record<Position, string> = {
    TOP: 'right-0 rtl:right-auto rtl:left-0 left-full',
    BOTTOM: 'right-0 rtl:right-auto rtl:left-0 top-full',
    RIGHT: 'right-full rtl:right-auto rtl:left-full top-0',
    LEFT: 'left-full rtl:left-auto rtl:right-full top-0'
  };

  return (
    <Popover className="relative">
      <Popover.Button className="btn-with-icon outline-btn !text-neutral-600 shrink-0">
        <Icon
          icon={btnIcon || 'bx:dots-horizontal-rounded'}
          width={18}
        />
        {btnTitle ? <span>{btnTitle}</span> : null}
      </Popover.Button>

      <Popover.Panel
        className={[
          'absolute z-10 ring-1 ring-neutral-200 bg-white rounded-lg w-max',
          positions[position]
        ].join(' ')}
      >
        <ul className="divide-y divide-neutral-200">{children}</ul>
      </Popover.Panel>
    </Popover>
  );
}
