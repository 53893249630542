import { RouteObject } from 'react-router-dom';
import {
  ActivityLogs,
  Admins,
  Branches,
  Cities,
  Clients,
  Countries,
  CreateProduct,
  Dashboard,
  Districts,
  ErrorPage,
  InvoiceDetails,
  Invoices,
  Login,
  NewInvoice,
  Permissions,
  Products,
  Profile,
  Regions,
  Stores,
  UpdateInvoices,
  UpdateProduct
} from 'src/screens';

const globalRoutes: RouteObject[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: RouteObject[] = [
  {
    path: '/account/login',
    element: <Login />
  },

  ...globalRoutes
];

const routes: RouteObject[] = [
  // {
  //   path: '/',
  //   element: <Dashboard />
  // },
  {
    path: '/account/profile',
    element: <Profile />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/products/add',
    element: <CreateProduct />
  },
  {
    path: '/products/:slug',
    element: <UpdateProduct />
  },
  // {
  //   path: '/clients',
  //   element: <Clients />
  // },
  {
    path: '/stores',
    element: <Stores />
  },
  {
    path: '/branches',
    element: <Branches />
  },
  {
    path: '/locations',
    element: <Countries />
  },
  {
    path: '/locations/regions',
    element: <Regions />
  },
  {
    path: '/locations/cities',
    element: <Cities />
  },
  {
    path: '/locations/districts',
    element: <Districts />
  },
  {
    path: '/activity-logs',
    element: <ActivityLogs />
  },
  {
    path: '/permissions',
    element: <Permissions />
  },
  {
    path: '/administration',
    element: <Admins />
  },
  {
    path: '/',
    element: <Invoices />
  },
  {
    path: '/invoices/new',
    element: <NewInvoice />
  },
  {
    path: '/invoices/:id',
    element: <InvoiceDetails />
  },
  {
    path: '/invoices/:id/update',
    element: <UpdateInvoices />
  },
  ...globalRoutes
];

const names: any[] = [
  // 'dashboard',
  'products',
  'invoices',
  'stores',
  'branches',
  'clients',
  'administration',
  'countries',
  'regions',
  'cities',
  'districts',
  'request_logs',
  'roles'
];

export { globalRoutes, authenticationRoutes, routes, names };
