import { Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { pick } from 'lodash';
import React, {
  Fragment,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'src/components/shared/Alert';
import Image from 'src/components/shared/Image';
import InputFile from 'src/components/shared/InputFile';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import useForm from 'src/hooks/useForm';
import useTitle from 'src/hooks/useTitle';

type StatusType = 'quotation' | 'delivery_receipt' | 'vat_invoice';

interface FormInterface {
  store_id: string | undefined;
  client_name: string | undefined;
  client_mobile: string | undefined;
  vat: string | undefined;
  invoice_no: string | undefined;
  invoice_date: string | undefined;
  branch_id: string | undefined;
  payment_method: string | undefined;
  discount: string | undefined;
  recipient_national_id: string | undefined;
  status: StatusType | undefined;
  products: any[] | undefined;
  attachments: any[] | undefined;
}

export default function Update() {
  let rerender: boolean = true;
  const navigate = useNavigate();
  const queryRef = useRef(null);
  const { updateTitle } = useTitle();
  const { id }: any = useParams();
  const [visible, setVisible] = useState<boolean>(true);
  const [checkoutVisible, setCheckoutVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>({});
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [responses, setResponses] = useState<any[]>([]);
  const [stores, setStores] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    search_key: ''
  });

  useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetStores(), GetProducts(), GetBranches(), GetInvoice()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  async function GetInvoice() {
    try {
      const { data } = await axiosInstance.get('/invoices/' + id);
      const invoice = data.result?.invoice;

      if (invoice?.status === 'vat_invoice') {
        return navigate('/', { replace: true });
      }

      setInitialValues({
        ...invoice,
        attachments: undefined,
        store_id: invoice?.store?.id,
        branch_id: invoice?.branch?.id
      });
      updateTitle({
        title:
          invoice?.invoice_no ||
          invoice?.client_name ||
          invoice?.branch?.name ||
          invoice?.store?.name
      });
    } catch (error) {
      console.log('error while sending request..');
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  }

  const GetStores = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('stores', { params: { is_active: 1 } });
      setStores(data?.result?.stores);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetBranches = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('branches', { params: { is_active: 1 } });
      setBranches(data?.result?.branches);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetProducts = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('products', { params: { is_active: 1 } });
      setResponses(data?.result?.products);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const globalValues = {
    client_name: undefined,
    client_mobile: undefined,
    invoice_no: undefined,
    invoice_date: new Date().toISOString().substring(0, 10),
    vat: undefined,
    store_id: undefined,
    branch_id: undefined,
    payment_method: 'deferred',
    attachments: undefined,
    discount: undefined,
    recipient_national_id: undefined,
    status: 'quotation',
    products: []
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = useState({
    ...globalValues,
    invoice_no: Math.floor(new Date().getTime() / 1000)
  });
  const [errors, setErrors] = useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
      let body: FormInterface,
        fd: FormData,
        link = document.createElement('a'),
        url: string;
      fd = new FormData();

      body = {
        ...values,
        products: values?.products?.map((ev: any) => {
          const { ...others } = pick(ev, ['id', 'qty', 'price', 'weight', 'product_type']);
          return {
            ...others
          };
        })
      };

      for (const key in body) {
        const item = body[key as keyof FormInterface];
        if (item) {
          if (key === 'products') {
            for (const index in item as any[]) {
              const product = item[index];
              fd.append('products[' + index + '][id]', product['id']);
              fd.append('products[' + index + '][qty]', product['qty']);
              fd.append('products[' + index + '][price]', product['price']);
              if (product['product_type'] === 'weight')
                fd.append('products[' + index + '][weight]', product['weight']);
            }
          } else if (key === 'attachments') {
            for (const idx in item as any[]) {
              const attachment = item[idx];
              if (attachment instanceof File) {
                fd.append('attachments[' + idx + ']', attachment, attachment.name);
              }
            }
          } else {
            fd.append(key, item as any);
          }
        }
      }

      const is_quote = body.status === 'quotation';
      const { data } = await axiosInstance.post('invoices/update', fd, {
        responseType: is_quote ? 'blob' : 'json'
      });

      if (is_quote) {
        url = URL.createObjectURL(new Blob([data]));

        link.href = url;
        link.download = new Date().getTime() + '.pdf';

        link.click();
        URL.revokeObjectURL(url);
        link.remove();
        return;
      }
      navigate('/', { replace: true });
      helper.restForm();
    } catch (error: AxiosError | any) {
      console.log(error);
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { setFieldValue, values, handleSubmit, handleChange }
  } = useForm({ initialValues, submitHandler });

  const UpdateCart = useCallback(
    function (item: any) {
      const cartItem = {
        ...item,
        qty: 1,
        display_weight: item.weight,
        ...(item.product_type === 'weight'
          ? { weight: item.product_type === 'weight' ? item.weight : {} }
          : {})
      };

      let result: any[] = [];

      if (values.products?.some((ev: any) => ev.id === item.id)) {
        result = values.products?.map((ev: any) => {
          if (ev.id === item.id) {
            return {
              ...ev,
              qty: +ev.qty + 1,
              ...(ev.product_type === 'weight'
                ? { weight: ev.product_type === 'weight' ? (ev.weight += ev.weight) : {} }
                : {})
            };
          }
          return ev;
        });
      } else {
        result = new Array().concat(values.products, cartItem);
      }

      setFieldValue('products', result);
      // setVisible((active) => active || true);
    },
    [values.products]
  );

  const DeleteCartItem = useCallback(
    function (item: any) {
      setFieldValue(
        'products',
        values.products?.filter((ev: any) => ev.id !== item.id)
      );
    },
    [values.products]
  );

  const UpdateItemQty = useCallback(
    function (item: any, value: string | number) {
      setFieldValue(
        'products',
        values.products?.map((ev: any) => {
          if (ev.id === item.id) {
            return {
              ...ev,
              qty: value
            };
          }
          return ev;
        })
      );
    },
    [values.products]
  );

  const UpdateItemWeight = useCallback(
    function (item: any, value: string | number) {
      setFieldValue(
        'products',
        values.products?.map((ev: any) => {
          if (ev.id === item.id) {
            return {
              ...ev,
              weight: value
            };
          }
          return ev;
        })
      );
    },
    [values.products]
  );

  const UpdateItemPrice = useCallback(
    function (item: any, value: string | number) {
      setFieldValue(
        'products',
        values.products?.map((ev: any) => {
          if (ev.id === item.id) {
            return {
              ...ev,
              price: value
            };
          }
          return ev;
        })
      );
    },
    [values.products]
  );

  const totalPrice = useMemo(() => {
    let total: number = 0;

    for (const item of Array.from(values.products || [])) {
      const product = item as any;
      total += +(product?.weight || 0) * +(product?.price || 0);
    }

    return CurrencyFormatter(total);
  }, [values.products]);

  const hasDiscount = useMemo(() => {
    const branch = branches?.find((ev: any) => ev.id === values.branch_id);

    if (branch) {
      return branch?.max_discount;
    }
    return null;
  }, [values.branch_id]);

  const handleInvoiceDiscount = useCallback(
    function ({ target }: { target: HTMLInputElement }) {
      const name = target.name;
      const value = target.value;

      if (hasDiscount) {
        setFieldValue(name, value > hasDiscount ? hasDiscount : value);
        return;
      }
      setFieldValue(name, value);
    },
    [values.branch_id]
  );

  return (
    <form
      className="p-8 pt-0 relative h-full"
      onSubmit={() => handleSubmit()}
    >
      {isLoading ? (
        <LoadingComponent position="absolute" />
      ) : (
        <Fragment>
          <div className="flex">
            <div className="flex-1">
              <ul className="grid grid-wrapper gap-4">
                {responses?.map((response: any, index: number) => (
                  <li
                    key={index}
                    className="flex flex-col rounded-xl overflow-hidden border border-neutral-200"
                  >
                    <Image
                      src={response.image}
                      className="w-full flex-1 rounded-t-lg object-cover max-h-[15rem] min-h-[15rem]"
                    />
                    <div className="p-4 space-y-1">
                      <p className="text-lg font-semibold text-primary">
                        {CurrencyFormatter(response.price)}{' '}
                        {response.product_type === 'weight' && (
                          <span className="align-middle text-sm text-red-500">(Per kilo)</span>
                        )}
                      </p>
                      <p className="text-sm font-semibold line-clamp-2">{response.name}</p>
                      <div className="!mt-4">
                        <button
                          type="button"
                          className="btn-with-icon bg-primary w-full !p-3 !rounded-full"
                          onClick={() => UpdateCart(response)}
                        >
                          <Icon
                            icon="ci:shopping-bag-02"
                            width={18}
                          />
                          <span className="align-middle">Add to invoice</span>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-5"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-5"
            show={visible}
          >
            <div className="fixed bottom-0 right-0 w-full sm:max-w-sm bg-neutral-50 z-10  border border-neutral-200 h-screen max-h-[calc(100vh-5rem)] rounded-t-xl sm:rounded-none sm:[border-start-start-radius:1rem] flex flex-col shadow-2xl shadow-neutral-600/20 overflow-hidden">
              <header className="px-6 py-4 border-b border-b-neutral-200 flex items-center gap-4 bg-white">
                <div className="space-y-1 flex-1">
                  <p className="text-neutral-500 text-sm">Details</p>
                  <p className="font-bold text-neutral-800 text-2xl">#{values.invoice_no}</p>
                </div>
                <button
                  type="button"
                  className="btn-with-icon !text-neutral-500 !bg-red-neutral-300 !rounded-full"
                  onClick={() => setVisible(false)}
                >
                  <Icon
                    icon="ion:close"
                    width={18}
                  />
                </button>
              </header>
              <p className="text-neutral-500 text-sm  py-4 px-6">
                Items ({values.products?.length || 0})
              </p>
              <div className="flex-1 overflow-y-auto border-t border-t-neutral-200">
                <div className="grid">
                  <ul>
                    {values.products?.map((item: any, i: number) => (
                      <li
                        key={i}
                        className="bg-white px-6 py-4 flex gap-4 items-start border-b border-b-neutral-200"
                      >
                        <Image
                          src={item.image}
                          className="w-12 h-12 rounded-full object-cover shrink-0"
                        />
                        <div className="flex-1 space-y-0.5">
                          <p className="text-primary font-bold text-lg break-all">
                            {CurrencyFormatter(
                              (item.product_type === 'weight' ? item.weight : item.qty) * item.price
                            )}
                          </p>
                          <p className="text-neutral-500 text-sm line-clamp-1">{item.name}</p>
                          <div className="!mt-3 pt-3 border-t border-t-neutral-200 grid grid-cols-2 gap-3">
                            <div className="form-group">
                              <p className="form-label">Quantity</p>
                              <input
                                type="number"
                                name={'qty-' + item.id}
                                id={'qty-' + item.id}
                                className="form-input !py-2.5"
                                placeholder="1"
                                min={1}
                                step="any"
                                value={item.qty}
                                onChange={({ target }: { target: HTMLInputElement }) => {
                                  UpdateItemQty(item, target.value);
                                }}
                              />
                              {errors?.['products.' + i + '.value'] ? (
                                <span className="form-error">
                                  {errors?.['products.' + i + '.value']}
                                </span>
                              ) : null}
                            </div>
                            {item.product_type === 'weight' && (
                              <div className="form-group">
                                <p className="form-label">Total weight</p>
                                <input
                                  type="number"
                                  name={'weight-' + item.id}
                                  id={'weight-' + item.id}
                                  className="form-input !py-2.5"
                                  placeholder="1"
                                  step="any"
                                  min={item.display_weight}
                                  value={item.weight}
                                  onChange={({ target }: { target: HTMLInputElement }) => {
                                    UpdateItemWeight(item, target.value);
                                  }}
                                />
                                {errors?.['products.' + i + '.weight'] ? (
                                  <span className="form-error">
                                    {errors?.['products.' + i + '.weight']}
                                  </span>
                                ) : null}
                              </div>
                            )}
                            <div
                              className={[
                                'form-group',
                                item.product_type === 'weight' ? 'col-span-2' : ''
                              ].join(' ')}
                            >
                              <p className="form-label">
                                Price{' '}
                                {item.product_type === 'weight' && (
                                  <span className="align-middle text-sm text-red-500">
                                    (Per kilo)
                                  </span>
                                )}
                              </p>

                              <input
                                type="number"
                                name={'price-' + item.id}
                                id={'price-' + item.id}
                                className="form-input !py-2.5"
                                placeholder="0.00 SAR"
                                min={item.min_price}
                                step="any"
                                value={item.price}
                                onChange={({ target }: { target: HTMLInputElement }) => {
                                  UpdateItemPrice(item, target.value);
                                }}
                                onBlur={({ target, ...others }: any) => {
                                  if (!['Backspace'].includes(others.code))
                                    UpdateItemPrice(
                                      item,
                                      +target.value < item.min_price ? item.min_price : target.value
                                    );
                                }}
                              />
                              {errors?.['products.' + i + '.price'] ? (
                                <span className="form-error">
                                  {errors?.['products.' + i + '.price']}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn-with-icon !text-red-500 !p-1 hover:!bg-red-500/10 !rounded-full"
                          onClick={() => DeleteCartItem(item)}
                        >
                          <Icon
                            icon="icon-park-outline:delete-themes"
                            width={18}
                          />
                        </button>
                        {errors?.['products.' + i + '.id'] ? (
                          <span className="form-error">{errors?.['products.' + i + '.id']}</span>
                        ) : null}
                      </li>
                    ))}
                    {!values.products?.length ? (
                      <li>
                        <p className="text-neutral-500 text-sm  py-4 px-6 text-center italic">
                          There are no items added to invoice yet
                        </p>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="py-4 px-6 border-t border-t-neutral-200 bg-white space-y-2">
                <select
                  id="invoice-status"
                  className="form-select"
                  defaultValue={''}
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                >
                  <option
                    value=""
                    selected
                    disabled
                  >
                    Status
                  </option>
                  {/* quotation,delivery_receipt,vat_invoice */}
                  <option value="quotation">Quotation</option>
                  <option value="delivery_receipt">Delivery receipt</option>
                  <option value="vat_invoice">VAT Invoice</option>
                </select>
                <button
                  className="btn-with-icon bg-primary w-full !p-3 !rounded-full"
                  type="button"
                  onClick={() => {
                    setCheckoutVisible(true);
                  }}
                >
                  <Icon
                    icon="fluent:payment-16-regular"
                    width={18}
                  />
                  <span className="align-middle">
                    Continue <span className="break-all">{totalPrice}</span>
                  </span>
                </button>
              </div>
            </div>
          </Transition>
          <button
            className="btn-with-icon bg-primary !p-3  !rounded-xl !px-6 !fixed bottom-5 right-5 rtl:right-auto rtl:left-5"
            onClick={() => setVisible(true)}
            type="button"
          >
            <Icon
              icon="ci:shopping-bag-02"
              width={18}
            />
            <span className="align-middle">
              Show invoice{' '}
              {values.products?.length ? <span>({values.products?.length || 0})</span> : null}{' '}
            </span>
          </button>
          {/* modal */}
          <Modal
            visible={checkoutVisible}
            // visible={true}
            title={'Checkout - ' + totalPrice}
            handleClose={() => setCheckoutVisible(false)}
            {...{
              size: values.is_quote ? undefined : 'max-w-screen-lg'
            }}
          >
            <Fragment>
              {errors ? (
                <Alert
                  title="Error"
                  type="error"
                  content={JSON.stringify(errors)}
                />
              ) : null}
              <div
                className={['grid grid-cols-1 gap-4', values.is_quote ? '' : 'md:grid-cols-2'].join(
                  ' '
                )}
              >
                <div className="space-y-4">
                  <p className="text-sm font-medium text-neutral-600">Client information</p>

                  <div className="form-group">
                    <label className="form-label">Store</label>
                    <Select
                      type={'single'}
                      options={stores}
                      value={values.store_id}
                      onSelect={function (value: any): void {
                        setFieldValue('store_id', value);
                      }}
                      optionTxt={'name'}
                      optionValue={'id'}
                    />
                    {errors?.store_id ? (
                      <span className="form-error">{errors?.store_id}</span>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Branch</label>
                    <Select
                      type={'single'}
                      options={branches}
                      value={values.branch_id}
                      onSelect={function (value: any): void {
                        setFieldValue('branch_id', value);
                      }}
                      optionTxt={'name'}
                      optionValue={'id'}
                    />
                    {errors?.branch_id ? (
                      <span className="form-error">{errors?.branch_id}</span>
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-group">
                      <label className="form-label">Client name</label>
                      <input
                        type="text"
                        required
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={values.client_name}
                        name="client_name"
                        onChange={handleChange}
                      />
                      {errors?.client_name ? (
                        <span className="form-error">{errors?.client_name}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Phone number</label>
                      <input
                        type="tel"
                        required
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={values.client_mobile}
                        name="client_mobile"
                        onChange={handleChange}
                      />
                      {errors?.client_mobile ? (
                        <span className="form-error">{errors?.client_mobile}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Recipient national ID</label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="..."
                      className="form-input form-outline"
                      value={values.recipient_national_id}
                      name="recipient_national_id"
                      onChange={handleChange}
                    />
                    {errors?.recipient_national_id ? (
                      <span className="form-error">{errors?.recipient_national_id}</span>
                    ) : null}
                  </div>
                </div>
                <div className="space-y-4">
                  <p className="text-sm font-medium text-neutral-600">Invoice information</p>
                  <div className="form-group">
                    <label className="form-label">Payment Type</label>
                    <div>
                      <ul className="space-y-2 !mt-4">
                        <li>
                          <div className="inline-flex gap-3 items-center">
                            <input
                              type="radio"
                              required
                              autoComplete="off"
                              name="product-type"
                              className="form-radio"
                              onChange={() => setFieldValue('payment_method', 'credit')}
                              value="credit"
                              checked={values.payment_method === 'credit'}
                              id="credit"
                            />
                            <label
                              className="form-label"
                              htmlFor="credit"
                            >
                              Credit
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="inline-flex gap-3 items-center">
                            <input
                              type="radio"
                              required
                              autoComplete="off"
                              name="product-type"
                              className="form-radio"
                              onChange={() => setFieldValue('payment_method', 'bank')}
                              value="bank"
                              checked={values.payment_method === 'bank'}
                              id="bank"
                            />
                            <label
                              className="form-label"
                              htmlFor="bank"
                            >
                              Bank transfer
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="inline-flex gap-3 items-center">
                            <input
                              type="radio"
                              required
                              autoComplete="off"
                              name="product-type"
                              className="form-radio"
                              onChange={() => setFieldValue('payment_method', 'cash')}
                              value="cash"
                              checked={values.payment_method === 'cash'}
                              id="cash"
                            />
                            <label
                              className="form-label"
                              htmlFor="cash"
                            >
                              Cash
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {errors?.payment_method ? (
                      <span className="form-error">{errors?.payment_method}</span>
                    ) : null}
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-group">
                      <label className="form-label">Invoice number</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={values.invoice_no}
                        name="invoice_no"
                        onChange={handleChange}
                      />
                      {errors?.invoice_no ? (
                        <span className="form-error">{errors?.invoice_no}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Invoice date</label>
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={values.invoice_date}
                        name="invoice_date"
                        onChange={handleChange}
                      />
                      {errors?.invoice_date ? (
                        <span className="form-error">{errors?.invoice_date}</span>
                      ) : null}
                    </div>
                  </div>
                  {hasDiscount && (
                    <div className="form-group">
                      <label className="form-label">Discount (%)</label>
                      <input
                        type="number"
                        autoComplete="off"
                        placeholder="00%"
                        className="form-input form-outline"
                        value={values.discount}
                        name="discount"
                        max={hasDiscount}
                        onChange={handleInvoiceDiscount}
                      />
                      {errors?.discount ? (
                        <span className="form-error">{errors?.discount}</span>
                      ) : null}
                    </div>
                  )}

                  <div className="form-group">
                    <label className="form-label">Attachments</label>
                    {
                      <InputFile
                        multiple
                        defaultValue={values.attachments}
                        onValueChange={function (value: any): void {
                          setFieldValue('attachments', value);
                        }}
                      />
                    }
                    {errors?.attachments ? (
                      <span className="form-error">{errors?.attachments}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="space-y-2 mt-4">
                <button
                  className="btn-with-icon bg-primary w-full !p-3 !rounded-full"
                  type="submit"
                  onClick={() => {
                    if (!values.is_quote) setFieldValue('is_quote', 0);
                    handleSubmit();
                  }}
                >
                  {disabled ? (
                    <Icon
                      icon="svg-spinners:3-dots-fade"
                      width={18}
                    />
                  ) : (
                    <Icon
                      icon="fluent:payment-16-regular"
                      width={18}
                    />
                  )}
                  <span className="align-middle">Save changes {totalPrice}</span>
                </button>
              </div>
            </Fragment>
          </Modal>
          {/* modal */}
        </Fragment>
      )}
    </form>
  );
}
