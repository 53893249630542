import React, { FC, useState } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/index';
import SharedTime from 'src/components/shared/SharedTime';
import Select from 'src/components/shared/Select';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ConfirmAlert } from 'src/hooks/alerts';

const Stores: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { user } = useSelector((state: RootState) => state.auth);
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: '',
    gender: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('stores', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.stores;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('stores/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('stores/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Latest update</th>
              <th>Actions</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>

                    <td>
                      <div className="inline-flex items-center gap-4">
                        <Switcher
                          checked={!!item.is_active}
                          onChange={function (checked: boolean): void {
                            ChangeStatus(item.id);
                          }}
                        />
                        <DotStatus active={!!item.is_active} />
                      </div>
                    </td>
                    <td>
                      <SharedTime date={item.updated_at || item.created_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2 items-center">
                        <button
                          className="btn-with-icon outline-btn justify-start"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          <Icon
                            icon="ri:eye-line"
                            width={19}
                          />
                        </button>
                        {user?.user_type === 'super_admin' && (
                          <button
                            className="btn-with-icon outline-btn text-start"
                            onClick={() => setDeleteConfirm(true)}
                          >
                            <span>Delete</span>
                          </button>
                        )}
                      </div>
                    </td>
                    <ConfirmAlert
                      visible={deleteConfirm}
                      requestClose={function (): void {
                        setDeleteConfirm(false);
                      }}
                      handleConfirm={function (): void {
                        DeleteItem(item.id);
                        setDeleteConfirm(false);
                      }}
                    />
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Stores table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-8 pt-0 space-y-4 grid">
        <div className="flex items-center justify-between flex-wrap">
          <div></div>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon outline-btn shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New store</span>
            </button>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        title="New store"
        handleClose={() => setVisible(false)}
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update store"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{
            ...editItem
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  tax_num: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const globalValues = {
    name: undefined,
    tax_num: undefined,
    alt_name: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('stores/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Tax number</label>
        <input
          type="text"
          autoComplete="off"
          className="form-input form-outline"
          value={formik.values.tax_num}
          onChange={(e) => handleChange('tax_num', e)}
        />
        {errors?.tax_num ? <span className="form-error">{errors?.tax_num}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('stores/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Tax number</label>
        <input
          type="text"
          autoComplete="off"
          className="form-input form-outline"
          value={formik.values.tax_num}
          onChange={(e) => handleChange('tax_num', e)}
        />
        {errors?.tax_num ? <span className="form-error">{errors?.tax_num}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

export default Stores;
