import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Link, redirect, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import Alert from 'src/components/shared/Alert';
import { useDispatch } from 'react-redux';
import { storeToken, saveUserInfo } from 'src/reducers/auth';
import Password from 'src/components/shared/Password';

interface AuthInterface {
  email_mobile?: string;
  password?: string;
}

const FormBody: React.FC = () => {
  const navigate = useNavigate();
  const globalValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const AuthHandler = React.useCallback(async function (
    values: AuthInterface,
    helper: FormikHelpers<AuthInterface>
  ): Promise<any> {
    try {
      setErrors(undefined);
      setDisabled(true);
      let token: string, user: any;
      const { data } = await axiosInstance.post('login', values);

      token = data.result.access_token;
      user = data.result.profile;
      sessionStorage.setItem('@token', token);
      helper.resetForm();
      // dispatch(storeToken(token));
      // dispatch(saveUserInfo(user));
      // navigate('/', { replace: true });
      window.location.reload();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while authenticating..');
        }
        return;
      }
      setErrors('Something went wrong while authenticating..');
    } finally {
      setDisabled(false);
    }
  },
  []);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: AuthHandler
  });

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        console.log('submission from form');
        handleSubmit();
      }}
    >
      {errors ? (
        <Alert
          title="Authentication error"
          type="error"
          content={errors?.toString()}
        />
      ) : null}

      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          E-mail address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-neutral-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          required
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          className="form-label"
        >
          Password
        </label>
        <Password
          name="password"
          id="password"
          placeholder="••••••"
          className="form-input form-outline !bg-neutral-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          required
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="!mt-8">
        <button
          className="btn-with-icon bg-primary w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Sign in</span>
          )}
        </button>
      </div>
    </form>
  );
};

const Login: React.FC = (): JSX.Element => {
  return (
    <div className="min-h-screen flex items-center justify-center p-6 bg-white">
      <figure className="shrink-0 w-full max-w-lg">
        <blockquote>
          <Link
            to="/"
            className="table mb-8 mx-auto"
          >
            <img
              src="/images/logo.png"
              alt="website logo"
              className="w-full max-w-[8rem]"
            />
          </Link>
          {/* <p className="text-2xl text-neutral-800 font-bold text-center">Hey, Hello 👋🏻</p>
          <p className="text-base text-neutral-500 font-medium mb-8 mt-2 text-center">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet tempora sed.
          </p> */}
          <FormBody />
        </blockquote>
      </figure>
    </div>
  );
};

export default Login;
