import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  RouteObject,
  redirect,
  useLocation,
  Location,
  Navigate
} from 'react-router-dom';
import LoadingComponent from './components/shared/LoadingComponent';
import { routes, authenticationRoutes } from './helper/routes';
import ScrollToTop from './hooks/ScrollToTop';
import useAuth from './hooks/useAuth';
import HeaderLayout from './layouts/HeaderLayout';
import { storeToken } from './reducers/auth';

import { RootState } from './store';

function AppWrapper() {
  const isLoading = useAuth();
  const { token } = useSelector((state: RootState) => state.auth);

  if (isLoading) return <LoadingComponent />;

  return (
    <Fragment>
      {token ? (
        <HeaderLayout>
          <Routes>
            {routes.map((route: any, index: string | number) => (
              <Route
                key={index}
                element={route.element}
                path={route.path}
              ></Route>
            ))}
          </Routes>
        </HeaderLayout>
      ) : (
        <Fragment>
          <Routes>
            {authenticationRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                {...route}
              ></Route>
            ))}
          </Routes>
        </Fragment>
      )}
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <AppWrapper />
      <ScrollToTop />
    </Router>
  );
}

export default App;

