export interface TitleInterface {
  [key: string]: string;
}

export const titles: TitleInterface = {
  // '/': 'Dashboard',
  '/products': 'Products',
  '/': 'Invoices',
  '/invoices/new': 'New invoice',
  '/stores': 'Stores',
  '/branches': 'Branches',
  '/products/add': 'New product',
  '/products/update': 'Update product',
  '/clients': 'Clients management',
  '/administration': 'Administration',
  '/notifications': 'Notifications',
  '/locations': 'Countries',
  '/locations/regions': 'Regions',
  '/locations/cities': 'Cities',
  '/locations/districts': 'Districts',
  '/activity-logs': 'Activity logs',
  '/permissions': 'Permissions',
  '/account/profile': 'Personal information'
};
