export default function CurrencyFormatter(value: number): string {
  const options = {
    style: 'currency',
    currency: 'SAR',
    currencyDisplay: 'code',
    currencySign: 'accounting',
    // notation: 'compact'
  } satisfies Intl.NumberFormatOptions;

  return new Intl.NumberFormat('en-SA', options).format(value);
}
