import React, { FC, useCallback, useMemo } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick, values } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/index';
import SharedTime from 'src/components/shared/SharedTime';
import Select from 'src/components/shared/Select';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';
import Dropdown from 'src/components/shared/Dropdown';
import Switcher from 'src/components/shared/Switcher';
import LocationTabs from 'src/components/shared/LocationTabs';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const Cities: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { user } = useSelector((state: RootState) => state.auth);
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [countries, setCountries] = React.useState<any[]>([]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems(), GetCountries(), GetRegions()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetCountries = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('countries', { params: { is_active: 1 } });
      setCountries(data?.result?.countries);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetRegions = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('regions');
      setRegions(data?.result?.regions);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'user_type',
        'gender'
      ]);

      const { data } = await axiosInstance.get('cities', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.cities;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...paginates, ...responsePaginate }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('cities/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('cities/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Alt name</th>
              <th>Country</th>
              <th>Region</th>
              <th>Status</th>
              <th>Latest update</th>
              <th>Actions</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>
                    <td>{item.alt_name || 'N/A'}</td>
                    <td>
                      <p>{item.country?.name || 'N/A'}</p>
                    </td>
                    <td>
                      <p>{item.region?.name || 'N/A'}</p>
                    </td>
                    <td>
                      <div className="inline-flex items-center gap-4">
                        <Switcher
                          checked={!!item.is_active}
                          onChange={function (checked: boolean): void {
                            ChangeStatus(item.id);
                          }}
                        />
                        <DotStatus active={!!item.is_active} />
                      </div>
                    </td>
                    <td>
                      <SharedTime date={item.updated_at || item.created_at} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Cities table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-8 pt-0 space-y-4 grid">
        <LocationTabs />
        <div className="flex items-center justify-between flex-wrap">
          <div></div>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon outline-btn shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New city</span>
            </button>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        title="New city"
        handleClose={() => setVisible(false)}
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
          countries={countries}
          regions={regions}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update city"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{
            country_id: editItem?.country?.id,
            region_id: editItem?.region?.id,
            ...editItem
          }}
          countries={countries}
          regions={regions}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  country_id: string | undefined;
  region_id: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching,
  countries,
  regions
}: {
  closeModal: any;
  reFetching: any;
  countries: any[];
  regions: any[];
}): JSX.Element => {
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    country_id: undefined,
    region_id: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('cities/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const filterRegions = useMemo(() => {
    return regions.filter((ev: any) => {
      // formik.setFieldValue('region_id', undefined);
      return formik.values.country_id
        ? ev.country?.id?.toString() === formik.values.country_id?.toString()
        : ev;
    });
  }, [formik.values.country_id]);

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Country</label>
        <Select
          type={'single'}
          options={countries}
          value={formik.values.country_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('country_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />
        {errors?.country_id ? <span className="form-error">{errors?.country_id}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Region</label>
        <Select
          type={'single'}
          options={filterRegions}
          value={formik.values.region_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('region_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />
        {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema,
  countries,
  regions
}: {
  closeModal: any;
  reFetching: any;
  countries: any[];
  regions: any[];
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
      console.log(values);
      const { data } = await axiosInstance.post('cities/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const filterRegions = useMemo(() => {
    return regions.filter((ev: any) => {
      if (schema.country_id !== formik.values.country_id)
        formik.setFieldValue('region_id', undefined);
      return formik.values.country_id
        ? ev.country?.id?.toString() === formik.values.country_id?.toString()
        : ev;
    });
  }, [formik.values.country_id]);

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={JSON.stringify(errors)}
        />
      ) : null}

      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt name</label>
        <input
          type="text"
          required
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Country</label>
        <Select
          type={'single'}
          options={countries}
          value={formik.values.country_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('country_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />
        {errors?.country_id ? <span className="form-error">{errors?.country_id}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Region</label>
        <Select
          type={'single'}
          options={filterRegions}
          value={formik.values.region_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('region_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />
        {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

export default Cities;
