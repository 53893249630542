import { Icon } from '@iconify/react';
import React, { DOMAttributes, FC, MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MoveToBottom, MoveToTop } from '../animations';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
  permission: string[];
}

const routes: RouterInterface[] = [
  // {
  //   title: 'Dashboard',
  //   icon: 'ri:home-5-line',
  //   path: '/',
  //   permission: ['show dashboard', 'edit dashboard', 'update dashboard', 'delete dashboard']
  // },
  {
    title: 'Invoices',
    icon: 'ci:shopping-bag-02',
    path: '/',
    permission: ['show invoices', 'edit invoices', 'update invoices', 'delete invoices']
  },
  {
    title: 'Products',
    icon: 'ci:shopping-bag-01',
    path: '/products',
    permission: ['show products', 'edit products', 'update products', 'delete products']
  },

  {
    title: 'Stores',
    icon: 'material-symbols:store-outline-rounded',
    path: '/stores',
    permission: ['show stores', 'edit stores', 'update stores', 'delete stores']
  },

  {
    title: 'Branches',
    icon: 'material-symbols:location-automation-outline',
    path: '/branches',
    permission: ['show branches', 'edit branches', 'update branches', 'delete branches']
  },

  {
    title: 'Administration',
    icon: 'ri:admin-line',
    path: '/administration',
    permission: [
      'show administration',
      'edit administration',
      'update administration',
      'delete administration'
    ]
  },
  // {
  //   title: 'Permissions',
  //   icon: 'carbon:ibm-cloud-pak-security',
  //   path: '/permissions',
  //   permission: ['show roles', 'edit roles', 'update roles', 'delete roles']
  // },
  {
    title: 'Locations',
    icon: 'ph:map-pin-line',
    path: '/locations',
    permission: [
      'show countries',
      'edit countries',
      'update countries',
      'delete countries',

      'show regions',
      'edit regions',
      'update regions',
      'delete regions',

      'show cities',
      'edit cities',
      'update cities',
      'delete cities',

      'show districts',
      'edit districts',
      'update districts',
      'delete districts'
    ]
  },
  {
    title: 'Activity logs',
    icon: 'basil:invoice-outline',
    path: '/activity-logs',
    permission: [
      'show request_logs',
      'edit request_logs',
      'update request_logs',
      'delete request_logs'
    ]
  }

  // {
  //   title: 'Settings',
  //   icon: 'ri:settings-line',
  //   path: '/settings'
  // }
];

const SideBar: FC = () => {
  const location = useLocation();
  const menuContainer = useRef<HTMLElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();

  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  const filterRoutes = useMemo(() => {
    return routes.filter((route) => {
      return user.permissions?.some((permission: string) => route.permission.includes(permission));
    });
  }, [user, routes]);

  return (
    <aside
      className="w-full lg:max-w-[18rem] shrink-0 bg-neutral-800/20 shadow-2xl shadow-neutral-600/5 fixed lg:relative z-20 hidden lg:block"
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div className="w-full max-w-[18rem] py-0 px-4 bg-white lg:bg-neutral-100 sticky top-0 min-h-screen max-h-screen overflow-y-auto flex flex-col border-r rtl:border-r-none rtl:border-l border-neutral-200">
        <div className="w-full border-b border-b-neutral-200 flex items-center justify-between py-4">
          <Link
            to="/"
            className="shrink-0"
          >
            <img
              src="/images/logo.png"
              alt="website logo"
              className="w-full max-w-[5rem] mx-auto block"
            />
          </Link>
          <button
            className="shrink-0 text-neutral-500 flex lg:hidden items-center justify-center"
            onClick={closeMenu}
          >
            <Icon
              icon="line-md:menu-to-close-transition"
              width={18}
            />
          </button>
        </div>
        <nav className="flex-1 py-4">
          <ul className="space-y-1">
            {filterRoutes.map((route: RouterInterface, index: string | number) => (
              <motion.li
                key={index}
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
              >
                <Link
                  to={route.path}
                  className={[
                    'w-full flex items-center gap-3 text-base py-2 px-3 rounded ',
                    new RegExp('^' + route.path + '$', 'gi').test(location.pathname)
                      ? 'bg-primary/10 text-primary font-semibold'
                      : 'text-neutral-500 font-medium'
                  ].join(' ')}
                >
                  <span className="shrink-0">
                    <Icon
                      icon={route.icon}
                      width={20}
                    />
                  </span>
                  <span>{route.title}</span>
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
        <div className="w-full border-t border-t-neutral-100 flex items-center justify-between py-4">
          <Link
            to="/account/profile"
            className="shrink-0 inline-flex gap-4"
          >
            <Image
              src={user?.image}
              className="shrink-0 w-10 h-10 rounded-full"
            />
            <div className="flex-1 grid">
              <motion.p
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
                className="text-base font-bold text-neutral-800 tabular-nums"
              >
                {user?.name || 'UNKNOWN'}
              </motion.p>
              <motion.a
                animate="visible"
                initial="hidden"
                variants={MoveToBottom}
                className="text-sm font-medium text-neutral-500 line-clamp-1"
              >
                {user?.email || 'UNKNOWN'}
              </motion.a>
            </div>
          </Link>
          <button
            className="shrink-0  text-neutral-500 flex items-center justify-center"
            onClick={handleLogged}
          >
            <Icon
              icon="heroicons-outline:logout"
              width={24}
            />
          </button>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
